import React from "react";
import { Col } from "react-bootstrap";
import { NioMedia, NioCard } from "../../components";

function Services({ icon, title, body, variant }) {
  return (
    <Col md={12} lg={12} className="my-4">
      <NioCard className="text-center  bg-transparent border-0">
        <NioCard.Body className="p-0 d-flex align-items-start gap-4">
          <NioMedia size="lg" img={icon} variant={variant} className="ml-2 services-icons" />
          <div className="text-end">
            <h5 className="content-color">{title}</h5>
            <p className="text-[16px]  text-justify">{body}</p>
          </div>
        </NioCard.Body>
      </NioCard>
    </Col>
  );
}
export default Services;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NioField } from "../../../components";
import { Col, Container, Dropdown, Row, DropdownButton, Button } from "react-bootstrap";
import api from "../../../data/axiosConfig";
// layouts
import AppLayout from "../../../layouts/AppLayout/AppLayout";

// Components
import { NioSection } from "../../../components";
import Card from "./Card";

function Sessions() {
  const [sessionsList, setSessionsList] = useState([]);
  const [searchType, setSearchType] = useState("all");
  const [searchTitle, setSearchTitle] = useState("البحث بإستخدام");
  const [params, setParams] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    fetchSessions();
  }, []);

  const fetchSessions = () => {
    api
      .get("pb/session/filter?status=NOT_STARTED&page=0&size=100")
      .then((res) => setSessionsList(res.data.payload.content))
      .catch((error) => console.log(error));
  };
  const refreshSessions = () => {
    fetchSessions();
  };
  useEffect(() => {
    filter();
  }, [searchType]);

  const filter = () => {
    let url = "";
    switch (searchType) {
      case "consultant":
        url = searchValue + "/0/100/";
        return url;
        break;
      case "all":
        url = `0/100/`;
        return url;
        break;

      default:
        url = `0/100/`;
        return url;
        break;
    }
  };

  const filterSessions = () => {
    api
      .get("pb/session/" + filter())
      .then((res) => setSessionsList(res.data.payload.content))
      .catch((error) => console.log(error));
  };

  const handleKeypress = (key) => {
    if (key === 13) {
      filterSessions();
    }
  };

  const sorted_array = sessionsList
    .slice()
    .sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

  return (
    <AppLayout variant={1} title="الجلسات" rootClass="layout-11">
      <div className="w-100 page-image"></div>

      {/*  Resource Section Start  */}
      <NioSection
        dir="ltr"
        className="overflow-hidden pt-10 pt-lg-20 pb-0"
        masks={["blur-1 left center"]}
      >
        <NioSection.Content>
          <Row className="justify-content-center text-center">
            <Col lg={8} xl={6}>
              <div className="nk-section-head pb-3">
                <h2>الجلسات الجماهيرية</h2>
              </div>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/*  Resource Section End  */}
      {/*  Course Section End   */}
      <NioSection className="nk-section-course pt-1">
        <div className="nk-section-head pb-1 nk-block-head-between align-items-lg-end flex-column flex-lg-row">
          <div className="text-end text-lg-end pb-5 pb-lg-0">
            <span className="fs-14 fw-semibold text-uppercase d-inline-block text-blue mb-2">
              جلساتنا
            </span>
            <h2>
              <span className="d-inline-block d-lg-block">البرامج الأكثر تفاعلا</span>
            </h2>
          </div>
        </div>
        <NioSection.Content>
          <Row className="gy-5 gy-xl-0">
            {sorted_array.map((item, i) => (
              <Card
                key={i}
                id={item.id}
                title={item.title}
                location={item.sessionLocation}
                attendant={item.attendants}
                rating={item.rates}
                duration={item.duration}
                sessionType={item.sessionType}
                sessionRequesterCategory={item.sessionRequesterCategory}
                sessionDay={item.sessionDay}
                sessionTime={item.sessionTime}
                sessionPath={item.sessionPath}
                guideline={item.guideline}
                startDate={item.startDate}
                endDate={item.endDate}
                requesterName={item.requesterName}
                consultantUsername={item.consultantUsername}
                refreshSessions={refreshSessions}
                desc={item.description}
                status={item.status}
              />
            ))}
          </Row>
        </NioSection.Content>
      </NioSection>
      {/*  Course Section End   */}
    </AppLayout>
  );
}

export default Sessions;

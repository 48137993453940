import React, { useEffect, useState } from "react";
import api from "../../data/axiosConfig";
import Card from "../inner-pages/workshops/Card";

function Sessions() {
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    fetchSessions();
  }, []);

  const fetchSessions = () => {
    api
      .get("/pb/session/filter?status=NOT_STARTED&page=0&size=4")
      .then((res) => setSessions(res.data.payload.content))
      .catch((error) => console.log(error));
  };

  const refreshSessions = () => {
    fetchSessions();
  };

  return (
    <>
      {sessions.map((item, i) => (
        <Card
          key={i}
          id={item.id}
          title={item.title}
          location={item.sessionLocation}
          attendant={item.attendants}
          rating={item.rates}
          duration={item.duration}
          sessionType={item.sessionType}
          sessionRequesterCategory={item.sessionRequesterCategory}
          sessionDay={item.sessionDay}
          sessionTime={item.sessionTime}
          sessionPath={item.sessionPath}
          guideline={item.guideline}
          startDate={item.startDate}
          endDate={item.endDate}
          requesterName={item.requesterName}
          consultantUsername={item.consultantUsername}
          refreshSessions={refreshSessions}
          desc={item.description}
          status={item.status}
        />
      ))}
    </>
  );
}
export default Sessions;

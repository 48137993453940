import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import { NioBadge, NioMedia, NioCard } from "../../../components";
import cardImg from "../../../assets/images/saip/arabsstock_P26534_large.jpg";
import image_icon from "../../../assets/images/file-icons/image.svg";
import video_icon from "../../../assets/images/file-icons/video.svg";
import report_icon from "../../../assets/images/file-icons/report.svg";
import guide_icon from "../../../assets/images/file-icons/guide.svg";
import manual_icon from "../../../assets/images/file-icons/manual.svg";
import article_icon from "../../../assets/images/file-icons/article.svg";
import Preview from "../../../assets/images/file-icons/preview.svg";
function Card({ title, category, desc, data }) {
  const baseUrl = process.env.REACT_APP_API_URL + "pb/content/attachment/preview";

  const fileType = () => {
    switch (data.consultationSection.toLowerCase()) {
      case "image":
        return image_icon;
      case "video":
        return video_icon;
      case "article":
        return article_icon;
      case "report":
        return report_icon;
      case "guide":
        return guide_icon;
      case "manual":
        return manual_icon;
      default:
        break;
    }
  };

  return (
    <>
      {data ? (
        <Col md={6} lg={4}>
          <NioCard>
            <NioCard.Body className="p-0">
              <div className="card-content px-4 py-2 d-flex flex-column justify-content-between h-100">
                <div>
                  <div className="pb-1 d-flex justify-content-between align-items-center">
                    <NioBadge label={category} rounded className={` text-bg-primary-soft`} />
                    <img
                      src={fileType(data.attachmentType)}
                      className="file-icon"
                      width={35}
                      height={35}
                    />
                  </div>
                  <h5 className="text-capitalize pt-2 text-center text-black clip-3 mb-3">
                    {title}
                  </h5>
                  <span className="lead-text fw-normal clip-3 mb-3">{desc}</span>
                </div>
                <div className="p-2 rounded-3">
                  <a
                    href={`${baseUrl}/${btoa(data?.attachments[0].id)}/${btoa(data?.attachments[0].attachmentId)}/`}
                    className="file-preview border border-primary-subtle py-2 rounded-1 d-flex gap-3 my-1 overflow-hidden"
                  >
                    <img
                      src={Preview}
                      className="mx-auto preview-icon"
                      alt="preview icon"
                      width={30}
                    />
                  </a>
                </div>
              </div>
            </NioCard.Body>
          </NioCard>
        </Col>
      ) : (
        <></>
      )}
    </>
  );
}
export default Card;

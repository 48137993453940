import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";

// components
import NioIcon from "../../../../NioIcon/NioIcon";

export default function FaqContent() {
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    getFaq();
  }, []);

  async function getFaq() {
    const response = await fetch(
      process.env.REACT_APP_API_URL +
        `pb/faq/filter?&page=0&size=100`
    );
    const faqsRes = await response.json();
    setFaqs(faqsRes.payload.content);
  }

  return (
    <div className="nk-faq">
      <Accordion className="accordion-s3 border border-lighter" defaultActiveKey={0}>
        {faqs &&
          faqs?.map((content, idx) => (
            <Accordion.Item className="py-4" eventKey={idx} key={idx}>
              <Accordion.Header as="div">
                <div className="h5 mb-0 text-capitalize text-break content-color" dir="rtl">
                  {content.question}
                </div>
                <NioIcon
                  size="sm"
                  name="downward-ios"
                  className="inline-block bg-gray-300 p-3 rounded-1 on"
                />
                <NioIcon
                  size="sm"
                  name="upword-ios"
                  className="inline-block bg-gray-300 p-3 rounded-1 off"
                />
              </Accordion.Header>
              <Accordion.Body>
                <p className="fs-16 text-base text-break content-color" dir="rtl">
                  {content.answer}
                </p>
              </Accordion.Body>
            </Accordion.Item>
          ))}
      </Accordion>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Dropdown } from "react-bootstrap";
import api from "../../../data/axiosConfig";

// layout
import AppLayout from "../../../layouts/AppLayout/AppLayout";

// components
import { NioSection, NioField } from "../../../components";

import pageImage from "../../../assets/images/saip/arabsstock_P26534_large.jpg";

// section content
import BlogsContent from "../../../components/PageComponents/InnerPages/Blogs/BlogsContent/BlogsContent";
import Card from "./Card";

const initialSearchValues = {
  title: "",
  areaId: null,
  section: undefined,
};

function ContentPage() {
  const [activeTag, setActiveTag] = useState("");
  const [searchValues, setSearchValues] = useState(initialSearchValues);
  const [results, setResults] = useState([]);
  const [areas, setAreas] = useState([]);
  const [placeHolder, setPlaceholder] = useState("مجال الملكية الفكرية");
  const [page, setPage] = useState(0);
  const tags = [
    {
      title: "تقرير",
      label: "REPORT",
    },
    {
      title: "دليل",
      label: "GUIDE",
    },
    {
      title: "مقال",
      label: "ARTICLE",
    },
    {
      title: "فيديو",
      label: "VIDEO",
    },
    {
      title: "صور",
      label: "IMAGE",
    },
    {
      title: "الكل",
      label: "",
    },
  ];

  const fetchContent = () => {
    api
      .get(
        `/pb/content/search?${
          searchValues.title !== "" ? "&title=" + searchValues.title : ""
        }${searchValues.areaId !== null ? "&consultationAreaId=" + searchValues.areaId : ""}${
          searchValues.section !== undefined ? "&section=" + searchValues.section : ""
        }&page=${page}&size=100`
      )
      .then((res) => setResults(res.data.payload.content))
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchContent();
  }, [searchValues]);

  useEffect(() => {
    getAreas();
  }, []);

  const hasAttachments = results.filter((item) => item.attachments.length > 0);

  async function getAreas() {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "pb/faq/questionConsultationArea"
    );
    const areasRes = await response.json();
    setAreas(areasRes.payload);
  }

  const selectValues = (array, val, lab) => {
    const values = array?.map((item) => ({
      value: item[val],
      label: item[lab],
    }));
    return values;
  };

  const areasValues = selectValues(areas, "id", "titleAr");
  areasValues.unshift({ value: undefined, label: "مجال الملكية الفكرية" });

  return (
    <AppLayout variant={1} title="الإرشاد الذاتي" rootClass="layout-11">
      <div className="w-100 page-image"></div>

      {/*  Resource Section Start  */}
      <NioSection
        dir="ltr"
        className="overflow-hidden pt-10 pt-lg-20 pb-0"
        masks={["blur-1 left center"]}
      >
        <NioSection.Content>
          <Row className="justify-content-center text-center">
            <Col lg={8} xl={6}>
              <div className="nk-section-head">
                <h2 className="text-primary">الإرشاد الذاتي</h2>
              </div>
            </Col>
            <Col lg={8}>
              <div className="nk-filter-wrap pb-5 pb-md-7">
                <Row>
                  <Col sm={4}>
                    <Dropdown drop="start">
                      <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        {placeHolder}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="text-end">
                        {areasValues.map((area) => (
                          <Dropdown.Item
                            aria-label={area.value}
                            onClick={(e) => {
                              setSearchValues({
                                ...searchValues,
                                areaId: e.target.ariaLabel,
                              });
                              setPlaceholder(area.label);
                            }}
                          >
                            {area.label}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  <Col sm={8}>
                    <NioField.Input
                      dir="rtl"
                      icon="search before z-1"
                      placeholder="بحث"
                      onChange={(e) =>
                        setSearchValues({ ...searchValues, title: e.target.value })
                      }
                    />
                  </Col>
                </Row>
                <div></div>
                <ul className="nk-tag justify-content-center pt-4">
                  {tags.map((tag, i) => {
                    return (
                      <li key={i}>
                        <div
                          className={`nk-tag-item ${
                            activeTag === tag.label ? "tag-active" : ""
                          }`}
                          aria-label={tag.label}
                          onClick={(e) => {
                            setSearchValues({ ...searchValues, section: e.target.ariaLabel });
                            setActiveTag(e.target.ariaLabel);
                          }}
                        >
                          {tag.title}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/*  Resource Section End  */}

      {/*  Latest Section Start  */}
      <NioSection
        className="nk-blog-section pt-0 border-bottom"
        masks={["blur-1 right bottom"]}
      >
        <NioSection.Content>
          <Row className="gy-5">
            {hasAttachments.map((item, i) => {
              return (
                <Card
                  key={i}
                  category={item.contentConsultationArea.titleAr}
                  title={item.title}
                  desc={item.description}
                  attachments={item.attachments}
                  data={item}
                />
              );
            })}
            <Card />
          </Row>
          {/* TODO pagination */}
          {/* <div className="nk-pagination-wrap d-flex flex-row-reverse flex-wrap flex-sm-nowrap align-items-center gap g-3 justify-content-center justify-content-md-between pt-5 pt-lg-7">
            <div className="nk-pagination-col">
              <p>
                عرض: <span>1-20 من 23 نتيجة</span>
              </p>
            </div>
            <div className="nk-pagination-col">
              <nav aria-label="Page navigation example">
                <ul className="pagination pagination-s1">
                  <li className="page-item d-none">
                    <Link className="page-link" to="#">
                      <NioIcon name="chevron-right" />
                      <span className="d-none d-sm-inline-block">السابق</span>
                    </Link>
                  </li>
                  <li className="page-item active">
                    <Link className="page-link" to="#">
                      1
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link className="page-link" to="#">
                      2
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link className="page-link" to="#">
                      3
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link className="page-link" to="#">
                      4
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link className="page-link" to="#">
                      <span className="d-none d-sm-inline-block">التالي</span>
                      <NioIcon name="chevron-left" />
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div> */}
          {/*  .nk-pagination-wrap  */}
        </NioSection.Content>
      </NioSection>
      {/*  Latest Section End  */}
    </AppLayout>
  );
}

export default ContentPage;

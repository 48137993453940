import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Collapse } from "react-bootstrap";
import api from "../../../data/axiosConfig";

function CustomModal({ show, handleClose, sessionId, refreshSessions, title }) {
  const initialFormData = {
    sessionId: sessionId,
    username: "",
    email: "",
    firstName: "",
    lastName: "",
    country: "",
    region: "",
    city: "",
    phone: "",
    attendantType: "",
    description: "",
    active: true,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formValid, setFormValid] = useState(false);
  const [termsError, setTermsError] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [status, setStatus] = useState("register");
  const [msg, setMsg] = useState(title);

  const textRegex = /^[\u0621-\u064A]+$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^[0-9]+$/;

  const validateField = (name, value) => {
    switch (name) {
      case "email":
        return emailRegex.test(value.trim()) ? "" : "بريد إلكتروني مطلوب/غير صالح";
      case "firstName":
        return textRegex.test(value.trim()) ? "" : "الاسم الأول مطلوب/غير صالح";
      case "lastName":
        return textRegex.test(value.trim()) ? "" : "اسم العائلة مطلوب/غير صالح";
      case "country":
        return textRegex.test(value.trim()) ? "" : "الدولة مطلوب/غير صالح";
      case "region":
        return textRegex.test(value.trim()) ? "" : "المنطقة مطلوب/غير صالح";
      case "city":
        return textRegex.test(value.trim()) ? "" : "المدينة مطلوب/غير صالح";
      case "phone":
        return phoneRegex.test(value.trim()) ? "" : "رقم الجوال يجب أن يحتوي على أرقام فقط";
      default:
        return "";
    }
  };
  const areFieldsFilledAndValid = () => {
    const requiredFields = [
      "email",
      "firstName",
      "lastName",
      "country",
      "region",
      "city",
      "phone",
    ];

    return requiredFields.every(
      (field) => formData[field].trim() !== "" && !formErrors[field]
    );
  };
  const canSubmit = () => {
    return areFieldsFilledAndValid() && termsAccepted;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newErrors = { ...formErrors, [name]: validateField(name, value) };
    setFormData({ ...formData, [name]: value });
    setFormErrors(newErrors);
    setFormValid(canSubmit());
  };
  useEffect(() => {
    if (!show) {
      setFormData({ ...initialFormData, sessionId: sessionId });
      setTermsAccepted(false);
      setShowTerms(false);
      setFormErrors({});
      setTermsError("");
      setFormValid(false);
    }
  }, [show, sessionId]);

  const handleSubmit = (formData) => {
    const url = "pb/session/attendant/";
    api
      .post(url, formData)
      .then((res) => {
        if (res.data.code === 200) {
          setStatus("success");
          refreshSessions();
        } else if (res.data.code === 500) {
          setStatus("error");
        }
      })
      .catch((error) => {
        console.error("Error:", console.log(error));
      });
  };

  const handleModalClose = () => {
    setTermsError("");
    setStatus("register");
    setFormData(initialFormData);
    handleClose();
  };

  const handleCheckboxChange = () => {
    setTermsError("");
    setTermsAccepted(!termsAccepted);
    setFormValid(canSubmit());
  };

  const toggleTerms = () => {
    setShowTerms(!showTerms);
  };
  const validateAllFields = () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      newErrors[key] = validateField(key, formData[key]);
    });
    setFormErrors(newErrors);

    // Check if terms are accepted
    if (!termsAccepted) {
      setTermsError("يجب قبول الشروط والأحكام");
    } else {
      setTermsError("");
    }

    return Object.values(newErrors).every((error) => error === "") && termsAccepted;
  };
  const onSubmit = () => {
    const isFormValid = validateAllFields();
    setFormValid(isFormValid);

    if (isFormValid) {
      setStatus("confirm");
    }
  };

  const formBody = (
    <Form>
      {/* Form fields */}
      {/* First Name field */}
      <div className="container">
        <div className="row">
          <div className="col">
            <Form.Group className="mb-3">
              <Form.Label>الاسم الأول</Form.Label>
              <Form.Control
                type="text"
                placeholder="أدخل اسمك الأول"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                isInvalid={!!formErrors.firstName}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.firstName}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="col">
            {/* Last Name field */}
            <Form.Group className="mb-3">
              <Form.Label>اسم العائلة</Form.Label>
              <Form.Control
                type="text"
                placeholder="أدخل اسم العائلة"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                isInvalid={!!formErrors.lastName}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.lastName}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {/* Phone field */}
            <Form.Group className="mb-3">
              <Form.Label>رقم الجوال</Form.Label>
              <Form.Control
                type="text"
                placeholder="أدخل رقم الجوال"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                isInvalid={!!formErrors.phone}
              />
              <Form.Control.Feedback type="invalid">{formErrors.phone}</Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="col">
            {/* Email field */}
            <Form.Group className="mb-3">
              <Form.Label>البريد الإلكتروني</Form.Label>
              <Form.Control
                type="email"
                placeholder="أدخل بريدك الإلكتروني"
                name="email"
                value={formData.email}
                onChange={handleChange}
                isInvalid={!!formErrors.email}
              />
              <Form.Control.Feedback type="invalid">{formErrors.email}</Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {/* Country field */}
            <Form.Group className="mb-3">
              <Form.Label>الدولة</Form.Label>
              <Form.Control
                type="text"
                placeholder="أدخل اسم الدولة"
                name="country"
                value={formData.country}
                onChange={handleChange}
                isInvalid={!!formErrors.country}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.country}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="col">
            {/* Region field */}
            <Form.Group className="mb-3">
              <Form.Label>المنطقة</Form.Label>
              <Form.Control
                type="text"
                placeholder="أدخل اسم المنطقة"
                name="region"
                value={formData.region}
                onChange={handleChange}
                isInvalid={!!formErrors.region}
              />
              <Form.Control.Feedback type="invalid">{formErrors.region}</Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="col">
            {/* City field */}
            <Form.Group className="mb-3">
              <Form.Label>المدينة</Form.Label>
              <Form.Control
                type="text"
                placeholder="أدخل اسم المدينة"
                name="city"
                value={formData.city}
                onChange={handleChange}
                isInvalid={!!formErrors.city}
              />
              <Form.Control.Feedback type="invalid">{formErrors.city}</Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
      </div>

      {/* Terms and conditions checkbox */}
      <Button className="mt-3" variant="outline-secondary" size="sm" onClick={toggleTerms}>
        عرض الشروط والأحكام
      </Button>
      <Form.Group className="mb-3 d-flex align-items-center mt-3">
        <Form.Check
          type="checkbox"
          id="termsCheckbox"
          checked={termsAccepted}
          onChange={handleCheckboxChange}
        />
        <Form.Label htmlFor="termsCheckbox" className="mx-1 mb-0">
          أوافق على الشروط والأحكام
        </Form.Label>
      </Form.Group>
      {termsError && <div className="invalid-feedback d-block">{termsError}</div>}

      <Collapse in={showTerms}>
        <div id="terms-text">
          {/* Your terms and conditions text here */}
          <p>هنا يمكنك إضافة نص الشروط والأحكام.</p>
          <p>هنا يمكنك إضافة نص الشروط والأحكام.</p>
          <p>هنا يمكنك إضافة نص الشروط والأحكام.</p>
          <p>هنا يمكنك إضافة نص الشروط والأحكام.</p>
          <p>هنا يمكنك إضافة نص الشروط والأحكام.</p>
          <p>هنا يمكنك إضافة نص الشروط والأحكام.</p>
          <p>هنا يمكنك إضافة نص الشروط والأحكام.</p>
          <p>هنا يمكنك إضافة نص الشروط والأحكام.</p>
          <p>هنا يمكنك إضافة نص الشروط والأحكام.</p>
          <p>هنا يمكنك إضافة نص الشروط والأحكام.</p>
          <p>هنا يمكنك إضافة نص الشروط والأحكام.</p>
          <p>هنا يمكنك إضافة نص الشروط والأحكام.</p>
          <p>هنا يمكنك إضافة نص الشروط والأحكام.</p>
        </div>
      </Collapse>
    </Form>
  );

  const registerButton = () => {
    switch (status) {
      case "register":
        return (
          <Button variant="primary" onClick={onSubmit}>
            تسجيل
          </Button>
        );
      case "confirm":
        return (
          <Button variant="primary" onClick={() => handleSubmit(formData)}>
            تأكيد
          </Button>
        );
      case "success" || "error":
        return <></>;
      default:
        break;
    }
  };

  return (
    <Modal className="saip-custom-modal" show={show} onHide={handleModalClose} centered>
      <div className="w-100">
        <Modal.Header className="saip-custom-modal-header" closeButton>
          <Modal.Title className="text-primary">التسجيل في الجلسة</Modal.Title>
        </Modal.Header>
      </div>
      <Modal.Body className={`saip-custom-modal-body`}>
        {status === "register" ? formBody : <></>}
        {status === "confirm" ? (
          <div className="success-message">هل أنت متأكد من التسجيل في الجلسة؟</div>
        ) : (
          <></>
        )}
        {status === "success" ? (
          <div className="success-message">تم تسجيلكم في {msg} بنجاح</div>
        ) : (
          <></>
        )}
        {status === "error" ? (
          <div className="success-message">حدث خطأ ما، يرجى المحاولة في وقت لاحق.</div>
        ) : (
          <></>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          إغلاق
        </Button>
        {registerButton()}
      </Modal.Footer>
    </Modal>
  );
}

export default CustomModal;

import { useState } from "react";
import classNames from "classnames";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import image_icon from "../../../assets/images/file-icons/image.svg";
import video_icon from "../../../assets/images/file-icons/video.svg";
import report_icon from "../../../assets/images/file-icons/report.svg";
import guide_icon from "../../../assets/images/file-icons/guide.svg";
import manual_icon from "../../../assets/images/file-icons/manual.svg";
import article_icon from "../../../assets/images/file-icons/article.svg";
import Preview from "../../../assets/images/file-icons/preview.svg";
// components
import NioCard from "../NioCard/NioCard";
import NioIcon from "../../NioIcon/NioIcon";
import NioBadge from "../../NioBadge/NioBadge";
import NioMedia from "../../NioMedia/NioMedia";
import NioButton from "../../NioButton/NioButton";

export default function NioBlogCard({
  variant,
  url,
  tags,
  title,
  desc,
  cover,
  shadow,
  className,
  children,
  attachments,
  type,
  id,
}) {
  const location = useLocation();
  const comClasses = classNames({
    [`card-blog`]: true,
    [`has-shadow`]: shadow,
    [`${className}`]: className,
  });

  const fileType = () => {
    switch (type.toLowerCase()) {
      case "image":
        return image_icon;
      case "video":
        return video_icon;
      case "article":
        return article_icon;
      case "report":
        return report_icon;
      case "guide":
        return guide_icon;
      case "manual":
        return manual_icon;
      default:
        break;
    }
  };

  function preview(fileId) {
    let newTabURL =
      process.env.REACT_APP_API_URL +
      "pb/content/attachment/preview/" +
      btoa(id) +
      "/" +
      btoa(fileId) +
      "/";
    window.open(newTabURL, "_blank");
  }

  return (
    <NioCard className={comClasses}>
      {variant === "one" ? (
        <>
          {/* Content card in Landing page */}
          <NioCard.Body className="p-0 landing-content-card">
            <div className="card-content px-4 py-2 d-flex flex-column justify-content-between h-100">
              <div>
                <div className="pb-1 d-flex align-items-center justify-content-between">
                  <NioBadge
                    label={tags}
                    rounded
                    className={` ${
                      location.pathname === "/blogs" ? "mb-3" : ""
                    } text-bg-primary-soft`}
                  />
                  <img
                    src={fileType(attachments[0].attachmentType)}
                    className="file-icon"
                    width={30}
                  />
                </div>
                <h5 className="text-capitalize pt-2 text-center content-color clip-3 mb-3">
                  {title}
                </h5>
                <span className="lead-text fw-normal text-center content-color clip-3 mb-3">{desc}</span>
              </div>
              <div className="p-2 rounded-3">
                <div
                  className="file-preview py-2 rounded-1 d-flex gap-3 my-1 overflow-hidden"
                  onClick={() => preview(attachments[0].attachmentId)}
                >
                  <img src={Preview} className="mx-auto preview-icon" alt="preview icon" width={30} />
                </div>
              </div>
            </div>
          </NioCard.Body>
        </>
      ) : variant === "two" ? (
        <>
          <NioCard.Body>
            {cover && (
              <div className="card-image">
                <Link to={url}>
                  <img src={cover} alt={title} className="card-img" />
                </Link>
              </div>
            )}

            <div className="card-content pt-5">
              {tags && (
                <ul className="pb-3">
                  {tags.map((tag, idx) => (
                    <li key={idx}>
                      <Link
                        to={tag.url}
                        className={`badge ${tag.badge} text-base text-uppercase fw-semibold`}
                      >
                        {tag.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
              {title && (
                <h5 className="card-title">
                  <Link to={url}>{title}</Link>
                </h5>
              )}
              <div className="card-divider"></div>
              <ul className="nk-list-meta text-base">
                <li>Feb 10, 2022</li>
                <li>8 min read</li>
              </ul>
            </div>
          </NioCard.Body>
        </>
      ) : variant === "three" ? (
        <>
          {cover && (
            <div className="card-image rounded-0">
              <Link to={url}>
                <img src={cover} alt={title} className="card-img-top img-fit-cover h-100" />
              </Link>
            </div>
          )}
          <NioCard.Body>
            <div className="card-content h-100 d-flex flex-column">
              {tags && (
                <div className="pb-3">
                  <span className="badge text-bg-primary-soft text-uppercase py-1 px-3">
                    {tags}
                  </span>
                </div>
              )}
              {title && (
                <h4 className="card-title">
                  <Link to={url}>{title}</Link>
                </h4>
              )}

              <div className="pt-5 mt-auto">
                <NioButton
                  as="link"
                  variant="danger"
                  className="btn-block"
                  label="Join for $11.99 only"
                />
              </div>
            </div>
          </NioCard.Body>
        </>
      ) : (
        <>{children}</>
      )}
    </NioCard>
  );
}

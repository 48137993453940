import { useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

// context
import DataProvider from "./context/DataProvider/DataProvider";

// landing page
import Landing from "./pages/landing/Landing";

// Homepages
import KidsCourse from "./pages/inner-pages/workshops";

// inner pages
import About from "./pages/inner-pages/about";
import Blogs from "./pages/inner-pages/content";

// miscellaneous pages
import NioScrollToTop from "./components/NioScrollToTop/NioScrollToTop";

function AppRoutes() {
  return (
    <Routes>
      {/* landing page */}
      <Route path="/" element={<Landing />} errorElement={<Navigate to="/" />} />
      {/* inner page  */}
      <Route path="/about" element={<About />} />
      <Route path="/content" element={<Blogs />} />
      <Route path="/workshops" element={<KidsCourse />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

function App() {
  useEffect(() => {
    document.body.classList.add("nk-body");
  }, []);

  return (
    <DataProvider>
      <BrowserRouter>
        <NioScrollToTop />
        <AppRoutes />
      </BrowserRouter>
    </DataProvider>
  );
}

export default App;

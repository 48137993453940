// components
import { NioMedia } from "../../components";

function Steps({ icon, step, title, desc }) {
  return (
    <li className="nk-schedule-item p-0">
      <div className="nk-schedule-item-inner flex-row">
        <div className="nk-schedule-symbol">
          <NioMedia size="md" rounded img={icon} />
        </div>
        <div className="nk-schedule-content mx-3" style={{ width: "100%" }}>
          {/* <span className="fs-14 fw-semibold text-uppercase mb-3">{step}</span> */}
          <div>
            <h4 className="mb-2 text-secondary">{title}</h4>
            <p className="fs-16 content-color">{desc}</p>
          </div>
        </div>
      </div>
    </li>
  );
}
export default Steps;

import React, { useState, useEffect } from "react";
import { SwiperSlide } from "swiper/react";

// components
import { NioBlogCard, NioSwiper } from "../../../../../components";
import placeholder from "../../../../../assets/images/saip/arabsstock_P26534_large.jpg";

export default function BlogsContent({ category }) {
  const [contentRes, setContentRes] = useState([]);

  useEffect(() => {
    category === "" ? getContent() : filterContent();
  }, [category]);

  async function getContent() {
    const response = await fetch(process.env.REACT_APP_API_URL + "pb/content/0/20/");
    const res = await response.json();
    setContentRes(res.payload.content);
  }

  async function filterContent() {
    const response = await fetch(
      process.env.REACT_APP_API_URL +
        "pb/content/search?" +
        new URLSearchParams({
          section: category,
          page: 0,
          size: 4,
        })
    );
    const res = await response.json();
    setContentRes(res.payload.content);
  }

  const hasAttachments = contentRes.filter((item) => item.attachments.length > 0);
  
  return (
    <NioSwiper
      gap={5}
      loop={false}
      duration={5}
      navigation={true}
      pagination={{
        clickable: true,
      }}
      // paginationClass="text-center text-lg-end"
      // wrapperClass="has-pagination pb-5 pb-lg-7"
      // navClass="swiper-button-group-s2 has-bg"
      // className="nk-swiper-s2 blog-slider overflow-visible"
      breakpoints={{ 0: { slidesPerView: "4" } }}
    >
      {hasAttachments &&
        hasAttachments.map((content, idx) => (
          <SwiperSlide key={idx} className="d-flex flex-wrap pb-2">
            <NioBlogCard
              variant="one"
              url="blog-details"
              tags={content.contentConsultationArea.titleAr}
              cover={placeholder}
              title={content.title}
              desc={content.description}
              attachments={content.attachments}
              type={content.consultationSection}
              id={content.id}
            />
          </SwiperSlide>
        ))}
    </NioSwiper>
  );
}

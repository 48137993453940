import React, { useState } from "react";
import { Link } from "react-router-dom";
import { NioButton, NioCard, NioIcon } from "../../../components";
import { Col, Row } from "react-bootstrap";
import CustomModal from "./CustomModal"; // Import the modal component

function Card({
  id,
  title,
  desc,
  location,
  attendant,
  rating,
  sessionDay,
  startDate,
  requesterName,
  status,
}) {
  const formatDate = (dateString) => {
    if (!dateString) {
      return "لم يتم التحديد";
    }
    const monthNames = [
      "يناير",
      "فبراير",
      "مارس",
      "أبريل",
      "مايو",
      "يونيو",
      "يوليو",
      "أغسطس",
      "سبتمبر",
      "أكتوبر",
      "نوفمبر",
      "ديسمبر",
    ];
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    return `${day} ${monthNames[monthIndex]}`;
  };
  const [modalShow, setModalShow] = useState(false);

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleModalShow = () => {
    setModalShow(true);
  };

  return (
    <Col md={6} xl={4} className="pt-5">
      <NioCard>
        <div className="card-image workshops-card-image">
          <img
            src="images/saip/arabsstock_P26534_large.jpg"
            alt="course"
            className="card-img-top img-fit-cover h-100"
          />
        </div>
        <NioCard.Body className="py-2">
          <div className="card-content h-100 d-flex flex-column">
            <div className="pb-1 align-self-start session-tag">
              <span className="badge fs-6 text-info text-bg-info-soft text-uppercase py-1 px-3">
                الجلسة مقدمة من
                {" " + requesterName}
              </span>
            </div>
            <h4 className="card-title mt-2 content-color">
              {title}
            </h4>
            <p className="content-color">{desc}</p>
            <Row className="g-3 pt-1 ">
              <Col lg={6}>
                <div className="nk-course-overview-list d-flex flex-column">
                  <div className="nk-course-overview-media text-info text-bg-info-soft p-1 rounded-1 xsmall-text mx-1 mb-1">
                    <p>موقع الجلسة</p>
                  </div>
                  <div className="nk-course-overview-content">
                    <span className="title content-color">{location.titleAr}</span>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="nk-course-overview-list d-flex flex-column">
                  <div className="nk-course-overview-media text-info text-bg-info-soft p-1 rounded-1 xsmall-text mx-1 mb-1">
                    <p>تاريخ الجلسة</p>
                  </div>
                  <div className="nk-course-overview-content">
                    <span className="title content-color">
                      {formatDate(sessionDay ? sessionDay : startDate)}
                    </span>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="nk-course-overview-list d-flex flex-column">
                  <div className="nk-course-overview-media text-info text-bg-info-soft p-1 rounded-1 xsmall-text mx-1 mb-1">
                    <p>عدد المسجلين</p>
                  </div>
                  <div
                    className="nk-course-overview-content"
                    data-bs-toggle="tooltip"
                    data-bs-title="<em>Tooltip</em> <u>with</u> <b>HTML</b>"
                  >
                    <span className="title content-color">{attendant.length}</span>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="pt-5 mt-auto">
              {status == "NOT_STARTED" ? (
                <NioButton
                  className="btn-block btn-secondary text-white"
                  label="تسجيل"
                  onClick={handleModalShow}
                />
              ) : (
                <NioButton
                  className="btn-block btn-secondary text-white"
                  label="الجلسة انتهت"
                  disabled
                />
              )}
            </div>
            <CustomModal
              show={modalShow}
              handleClose={handleModalClose}
              sessionId={id}
              title={title}
            />
          </div>
        </NioCard.Body>
      </NioCard>
    </Col>
  );
}
export default Card;

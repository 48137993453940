import register from './first.svg';
import request from './second.svg';
import search from './search.svg';
import support from './support.svg';
import protection from './protection.svg';
import diagnosys from './diagnosis.svg';
import limeCheck from './lime-check.svg'


export const registerIcon = register;
export const requestIcon = request;
export const searchIcon = search;
export const supportIcon = support;
export const protectionIcon = protection;
export const diagnosysIcon = diagnosys;
export const limeCheckIcon = limeCheck;



import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

import Services from "./Services";
// layout
import AppLayout from "../../layouts/AppLayout/AppLayout";

// components
import { NioButton, NioCount, NioMedia, NioSection } from "../../components";

// section content
import FaqContent from "../../components/PageComponents/Homepages/BSSubscription/FaqContent/FaqContent";
import TestimonialContent from "../../components/PageComponents/Homepages/BSSubscription/TestimonialContent/TestimonialContent";
import BlogsContent from "../../components/PageComponents/InnerPages/Blogs/BlogsContent/BlogsContent";

// images
import { registerIcon, requestIcon, limeCheckIcon } from "../../assets/images/saip/icons";
import Steps from "./Steps";
import Courses from "./Courses";

function Landing() {
  const [activeTag, setActiveTag] = useState("");

  const services = [
    {
      icon: registerIcon,
      title: "مسار خدمات الإرشاد والتوجيه",
      body: "يتم عن طريق هذا المسار تقديم خدمات الإرشاد الفني والقانوني  لتوجيه المستفيدين في الاستفسارات المتعلقة بتسجيل وحماية حقوق الملكية الفكرية وطنياً ودولياً وحول استراتيجيات إدارة حقوق الملكية الفكرية و تعزيزها  و آلية الاستفادة منها وذلك إما حضوريا أو افتراضياً.",
    },
    {
      icon: requestIcon,
      title: "مسار الخدمات الفنية",
      body: "يتم عن طريق هذا المسار تقديم خدمات الخدمات الفنية المتخصصة مثل خدمات تحليل السوق  و المنافسين و إصدار تقارير البحث في التقنيات السابقة (prior art search) والتي تتيح للمستفيدين تحديد جدة الفرصة الابتكارية أو تطويرها.",
    },
    {
      icon: requestIcon,
      title: "مسار برنامج الدعم للمنشآت القائمة على الملكية الفكرية",
      body: "يتضمن هذا المسار برنامج مسرعة الملكية الفكرية والتي تهدف إلى تمكين المنشآت القائمة على حقوق الملكية الفكرية، وذلك من خلال تدريب المشاركين وتأهيلهم وربطهم بشبكة من الشركاء في قطاعات الأعمال والمستثمرين في القطاعات ذات الصلة بمنتجاتهم بهدف تحويل حقوق الملكي الفكرية إلى منتجات أو تمويل المنتجات القائمة.",
    },
  ];

  const steps = [
    {
      icon: limeCheckIcon,
      step: "الخطوة الأولى",
      title: "إنشاء حساب في البوابة",
      desc: "إنشاء حساب و تسجيل الدخول في بوابة عيادات الملكية الفكرية عن طريق خدمة النفاذ الوطني",
    },
    {
      icon: limeCheckIcon,
      step: "الخطوة الثانية",
      title: "تقديم طلب الإرشاد",
      desc: "تقديم طلب الحصول على الخدمة بتحديد مجال الخدمة المطلوبة من القائمة واستكمال نموذج طلب الخدمة",
    },
    {
      icon: limeCheckIcon,
      step: "الخطوة الثالثة",
      title: "تقديم الإرشاد من خلال الخبراء",
      desc: "تقديم الخدمة وفق الموعد المجدول وإرفاق التوصيات عند الانتهاء من الجلسة",
    },
  ];

  const tags = [
    {
      title: "مقالات وتقارير",
      label: "ARTICLE",
    },
    {
      title: "فيديو وصور",
      label: "MEDIA",
    },
  ];
  const url = process.env.REACT_APP_EXTERNAL_CLIENT_URL;
  return (
    <AppLayout
      variant={1}
      title="عيادات الملكية الفكرية  للملكية الفكرية"
      rootClass="layout-11"
    >
      {/*  Banner Section Start   */}
      <section className="nk-banner saip-landing-banner is-theme is-theme-bg min-vh-100">
        <div className="nk-banner-wrap">
          <Container>
            <Row className="align-items-center justify-content-lg-between position-relative">
              <Col lg={6}>
                <div className="nk-section-head text-left pb-5 pb-sm-7 pb-lg-0">
                  <h1 className="display-6 mt-8 mb-md-5">عيادات الملكية الفكرية</h1>
                  <p className="fs-20 mb-0 title-color">
                    من هنا نبدأ معك رحلة حفظ حقوقك .. شركائك من الفكرة حتى السوق!
                  </p>
                  <p className="text-[12px] mt-4 text-justify">
                    عيادات الملكية الفكرية (IP Clinics) ، أحد برامج تمكين الملكية الفكرية والتي
                    تتضمن حزمة واسعة من الخدمات المتخصصة في (3) مسارات رئيسية هي مسار الإرشاد
                    والتوجيه، ومسار الخدمات الفنية المتخصصة، ومسار مسرعات الأعمال للمنشآت
                    القائمة على الملكية الفكرية يقدمها كوكبة من الخبراء و المختصين المحليين
                    والدوليين في المجال، بما يكفل رفع فرصكم في تحويل أفكاركم الإبداعية إلى
                    منتجات مبتكرة عن طريق تسجيل الحقوق وحمايتها والاستفادة منها.
                  </p>
                  <ul className="nk-btn-group justify-content-center justify-content-lg-start pt-5 pt-lg-6">
                    <li>
                      <NioButton
                        href={url + 'newRequest'}
                        target="_blank"
                        label="طلب إرشاد"
                        className="btn btn-secondary text-nowrap"
                      />
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      {/*  Banner Section End   */}

      {/* features Section Start */}
      <Container>
        <Row className="gy-5 gy-lg-7">
          <Col lg={7}>
            <NioSection className="nk-section-features py-7 py-lg-120">
              <h2 className="title-color">خدمات عيادات الملكية الفكرية</h2>
              <NioSection.Content>
                <Row className="mt-5">
                  {services.map((service, i) => {
                    return (
                      <Services
                        key={i}
                        icon={service.icon}
                        title={service.title}
                        body={service.body}
                        variant={service.variant}
                      />
                    );
                  })}
                </Row>
              </NioSection.Content>
            </NioSection>
          </Col>
          <Col lg={5}>
            <div className="nk-banner-cover nk-clinic-services nk-frame nk-frame-three">
              <div className="services-image"></div>
            </div>
          </Col>
        </Row>
      </Container>
      {/* features Section End */}

      <NioSection className="nk-section-course pt-7 pt-lg-120">
        <div className="nk-section-head pb-md-7 nk-block-head-between align-items-lg-end flex-column flex-lg-row">
          <div className="text-center text-lg-start pb-5 pb-lg-0 d-flex flex-column ">
            <h2 className="text-end">
              <span className="d-inline-block d-lg-block title-color">
                روزنامة الجلسات الإرشادية الجماعية
              </span>
            </h2>
            <p className="fs-14 fw-semibold text-uppercase content-color text-end mt-5 w-75 d-inline-block text-justify">
              تقدم عيادات الملكية الفكرية مجموعة من الجلسات الإرشادية المتاحة للعامة في أبرز
              مسائل وموضوعات الملكية الفكرية الأكثر طلباً. حيث توفر الروزنامة أدناه قائمة
              الجلسات المجدولة لهذا العام والمتاحة للتسجيل.
            </p>
          </div>
          <div className="text-center text-lg-start">
            <NioButton
              href="/workshops"
              className="btn btn-sm btn-secondary"
              label="عرض الكل"
              style={{ width: "max-content" }}
            />
          </div>
        </div>
        <NioSection.Content>
          <Row className="gy-5 gy-xl-0">
            <Courses />
          </Row>
        </NioSection.Content>
      </NioSection>
      {/* Tracks Section */}
      <NioSection dir="ltr" className="overflow-hidden pt-7" masks={["blur-1 left center"]}>
        <NioSection.Content>
          <Row className="justify-content-end">
            <Col lg={12} xl={12}>
              <div className="nk-section-head text-end">
                <h2 className="title-color">دليلك الشامل في مجالات الملكية الفكرية</h2>
                <p className="fs-14 fw-semibold text-uppercase content-color text-end mt-5 w-75 d-inline-block">
                  تقدم عيادات الملكية الفكرية مجموعة من الأدلة الإرشادية والمواد المرئية في
                  مجالات الملكية الفكرية بهدف دعم المستفيدين في الوصول للمعلومات ذات الصلة
                  بتسجيل الحقوق أو حمايتها أو الاستفادة منها بشكل ذاتي وسهل.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mb-7">
            <Col>
              <Link to="/content" className="btn btn-sm btn-secondary">
                عرض الكل
              </Link>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <BlogsContent category={activeTag} />
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>

      {/* End of Tracks Section */}

      {/* funfact Section Start */}
      <NioSection className="nk-funfact-section pt-7 pt-lg-120 border-top border-bottom border-1">
        <NioSection.Content>
          <Row className="align-items-center justify-content-between text-center text-lg-start">
            <Col lg={2}>
              <div className="nk-section-head pb-7 pb-lg-0 text-end">
                <h3 className="title-color">أرقامنا</h3>
                <span className="d-inline-block fs-21 fw-bold text-uppercase content-color mb-2">
                  فخورين بمساعدتكم
                </span>
              </div>
            </Col>
            <Col lg={10}>
              <Row className="gy-5 gy-md-0 justify-content-center">
                <Col>
                  <div className="text-center">
                    <div>
                      <NioCount className="h2 d-block content-color" suffix="+" end={6705} />
                      <p className="fs-18 content-color">مستفيد من الخدمة</p>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="text-center">
                    <div>
                      <NioCount className="h2 d-block content-color" suffix="+" end={3074} />
                      <p className="fs-18 content-color">جلسة إرشادية</p>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="text-center">
                    <div>
                      <NioCount className="h2 d-block content-color" suffix="+" end={65} />
                      <p className="fs-18 content-color">جلسة جماعية</p>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="text-center">
                    <div>
                      <NioCount className="h2 d-block content-color" end={1124} />
                      <p className="fs-18 content-color">عدد المنشآت المستفيدة</p>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="text-center">
                    <div>
                      <NioCount className="h2 d-block content-color" end={20} />
                      <p className="fs-18 content-color">مستشار متخصص</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>

      {/* Process Section Start */}
      <NioSection className="nk-how-it-work-section bg-gray overflow-hidden">
        <Row className="justify-content-start">
          <Col>
            <div className="nk-section-head text-end pb-7">
              <span className="d-inline-block fs-14 fw-bold text-uppercase content-color mb-2">
                بوابة عيادات الملكية الفكرية
              </span>
              <h2 className="title-color">استخدام بوابة عيادات الملكية الفكرية </h2>
              <p className="fs-20 mb-0 content-color">
                لطلب الحصول على أي من خدمات العيادات المقدمة، يمكنكم تقديم الطلب عن طريق بوابة
                العيادات باتباع الخطوات التالية:
              </p>
            </div>
          </Col>
        </Row>
        <NioSection.Content>
          <Row className="flex-row-reverse align-items-center justify-content-between">
            <Col>
              <ul className="nk-schedule d-flex flex-column gap-5 nk-schedule-s2">
                {steps.map((step, i) => (
                  <Steps
                    key={i}
                    icon={step.icon}
                    step={step.step}
                    title={step.title}
                    desc={step.desc}
                  />
                ))}
              </ul>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/* Process Section End */}
      {/* Faqs Section Start */}
      <NioSection className="nk-section-faq">
        <Row className="justify-content-between">
          <Col xl={4}>
            <div className="nk-section-head pb-5 pb-xl-0">
              <h2 className="title-color">الأسئلة الشائعة</h2>
              <ul className="nk-btn-group pt-5">
                <li>
                  <NioButton
                    href="https://www.saip.gov.sa/contact-us/"
                    target="_blank"
                    label="تواصل معنا"
                    className="text-info text-bg-info-soft"
                  />
                </li>
              </ul>
            </div>
          </Col>
          <Col xl={8}>
            <FaqContent />
          </Col>
        </Row>
      </NioSection>
      {/* Faqs Section End */}
    </AppLayout>
  );
}

export default Landing;
